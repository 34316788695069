import classNames from 'classnames'
import React from 'react'

const PreLoaderProgressBar = (props) => {
  const { label, count, width, isVertical, isLoading, color = 'accent0' } = props
  
  const wrapperClasses = classNames(
    'homepage-item-bar-wrapper', {
    'homepage-item-bar-wrapper--vertical': isVertical,
    'homepage-item-bar-wrapper--loading': isLoading
  })

  const nameClasses = classNames(
    'homepage-item-bar-name', {
    'homepage-item-bar-name--vertical': isVertical
  })

  const barClasses = classNames(
    'billing-limit-progress', 
    `billing-limit-progress--${color}`
  )

  return (
    <div className={wrapperClasses}>
      <div className={nameClasses}>{label}</div>
      <div className='vertical-center'>
        {(count === 0 || count) && <div className='homepage-item-bar-count'>{count}</div>}
        {width &&
          <div>
            <div className='billing-limit-progress-container'>
              <div
                className={barClasses}
                style={{ width: width }}
              />
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default PreLoaderProgressBar