export const routeMapping = {
  DASHBOARD: { path: '/monitor' },
  DASHBOARDS: { path: '/dashboards' },
  CREATE_DASHBOARD: { path: '/dashboard/create'},
  DEVICES: { path: '/devices' },
  DEVICES_IMAGES: { path: '/devices-images' },
  DEVICES_DETAIL: { path: '/devices/:id' },
  DEVICES_ANALYTIC: { path: '/devices/:id/:name' },
  DEVICE_TERMINAL: { path: '/devices/:id/terminal' },
  DEVICES_DRS_TAB: { path: '/devices/:id/drs' },
  DEVICES_EDIT_TAB: { path: '/devices/:id/edit' },
  DEVICES_HEALTH_DETAIL: { path: '/devices/:id/:name' },
  DEVICE_CONTAINER_MANAGER: { path: '/devices/:id/container/list' },
  DEVICE_HEALTH_CHECK: { path: '/devices/:id/health/check' },
  DEVICE_GROUPS: { path: '/settings/device-groups' },
  DEVICE_GROUP_DETAIL: { path: '/settings/device-groups/:id' },
  DEVICE_GROUP_DETAIL_EDIT: { path: '/settings/device-groups/:id/edit' },
  PROFILE_DETAIL: { path: '/profiles/:id' },
  DEPLOYMENT_PROFILES: { path: '/profiles' },
  DEPLOYMENT_DEVICES: { path: '/devices' },
  DEPLOYMENT_DEVICES_MANAGER: { path: '/device-manager' },
  DEPLOYMENT: { path: '/deployment' },
  REQUIRED_MODULES: { path: '/required-modules' },
  LIBRARY_APPLICATION: { path: '/applications/:id' },
  LIBRARY: { path: '/library' },
  LIBRARY_APPLICATIONS: { path: '/applications' },
  LIBRARY_VIDEOS: { path: '/videos' },
  LIBRARY_MODELS: { path: '/models' },
  LIBRARY_DATASETS: { path: '/dataset' },
  LIBRARY_DATASETS_CVAT_CREATE: { path: '/dataset/:id/cvat' },
  LIBRARY_DATASET: { path: '/dataset/:id' },
  LIBRARY_MODEL: { path: '/model/:id' },
  LIBRARY_IMAGE_ANNOTATION: { path: '/image-annotation' },
  LIBRARY_ENVIRONMENTS: { path: '/image-annotation' },
  LIBRARY_MODEL_TRAINING: { path: '/model-training' },
  LIBRARY_JOB_TRAINING: { path: '/job-training' },
  LIBRARY_ENVIRONMENT: { path: '/model/:id' },
  LIBRARY_MODELS_CREATE: { path: '/models/create' },
  LIBRARY_EXPERIMENT_CREATE: { path: '/experiment/create' },
  LIBRARY_DATASET_CREATE: { path: '/dataset/create' },
  LIBRARY_ENVIRONMENT_CREATE: { path: '/environment/create' },
  LIBRARY_VIDEO: { path: '/video/:id' },
  APPLICATIONS_COMMIT_PAGE: { path: '/application/commit' },
  APPLICATIONS_VISUAL_EDITOR_PAGE: { path: '/application/visual-editor' },
  APPLICATIONS_VISUAL_EDITOR_EDIT_PAGE: { path: '/application/visual-editor/edit' },
  APPLICATIONS_HISTORIES_PAGE: { path: '/application/history' },
  APPLICATIONS_HISTORY_PAGE: { path: '/application/history/:id' },
  APPLICATIONS_VERSION_PAGE: { path: '/application/version/:id' },
  APPLICATIONS_VERSIONS_PAGE: { path: '/application/version' },
  NEW_LIBRARY_APPLICATION: { path: '/applications/new' },
  NEW_LIBRARY_VIDEO: { path: '/videos/new' },
  NEW_LIBRARY_APPLICATION_SCRATCH: { path: '/applications/new/scratch' },
  NEW_LIBRARY_APPLICATION_TEMPLATE: { path: '/applications/template' },
  NEW_LIBRARY_APPLICATION_IMPORT: { path: '/applications/import' },
  LIBRARY_MODULES: { path: '/modules' },
  LIBRARY_MODULE: { path: '/modules' },
  LIBRARY_CUSTOM_CREATE: { path: '/module/custom' },
  LIBRARY_CUSTOM_MODULE_LIST: { path: '/module/list' },
  LIBRARY_CREATE_MODULE_VERSION: { path: '/modules/:id/version' },
  MODULE_DETAILS: { path: '/modules/:id' },
  APPLICATION_INFORMATION_EDIT: { path: '/applications/:id/edit' },
  IMPORT_APPLICATION: { path: '/application/clone' },
  MODULE_INFORMATION_EDIT: { path: '/applications/:id/edit' },
  IMPORT_MODULE: { path: '/module/import' },
  LOGIN: { path: '/login' },
  AUTHENTICATION: { path: '/authentication' },
  INVALID_TOKEN: { path: '/invite/error' },
  VERIFY_EMAIL: { path: '/verify_email/:token' },
  CHOOSE_PLANS: { path: '/get-started' },
  OVERVIEW: { path: '/' },
  PROFILE: { path: '/settings/profile' },
  REGISTER: { path: '/register' },
  REPORTS: { path: '/reports' },
  REPORT_EDIT: { path: '/reports/:id/edit' },
  REPORT_DETAIL: { path: '/reports/:id' },
  BILLING: { path: '/settings/billing' },
  PARTNER: { path: '/settings/partner' },
  PARTNER_NEW: { path: '/settings/partner/update' },
  PARTNER_DETAIL: { path: '/settings/partner/:id' },
  MANAGE_SUBSCRIPTION: { path: '/settings/billing/manage' },
  BILLING_QUOTA: { path: '/settings/quota' },
  NEW_WORKSPACE: { path: '/settings/workspace/new' },
  SELECT_WORKSPACE: { path: '/workspace/select' },
  CHANGE_PASSWORD: { path: '/change-password' },
  SUBSCRIBE_MARKETPLACE: { path: '/marketplace/subscribe' },
  MANAGE_WORKSPACE: { path: '/settings/manage/workspaces' },
  WORKSPACE: { path: '/settings/workspace' },
  ROLE_DETAIL: { path: '/settings/role/:id' },
  SETTINGS: { path: '/settings' },
  SETTINGS_TAB: { path: '/settings/:tab' },
  SETTINGS_ROLE_MANAGE: { path: '/settings/role/manage' },
  SETTINGS_ROLE: { path: '/settings/role' },
  SETTINGS_USER: { path: '/settings/user' },
  SETTINGS_SCOPE: { path: '/settings/scope' },
  SETTINGS_SCOPE_DETAIL: { path: '/settings/scope/:id' },
  SETTINGS_GROUP: { path: '/settings/user_group' },
  SETTINGS_USER_GROUP_DETAIL: { path: '/settings/user_group/:id' },
  SETTINGS_USER_GROUP_EDIT: { path: '/settings/user_group/:id/edit' },
  SETTINGS_USER_DETAIL: { path: '/settings/user/:id' },
  MARKETPLACE: { path: '/marketplace' },
  MARKETPLACE_DETAILS: { path: '/marketplace/:id' },
  SOLUTION_PREVIEW: { path: '/solution/preview/:id' },
  LICENSE_LIST: { path: '/settings/licenses' },
  SETTING_DEVICE_TYPE_LIST: { path: '/device-images' },
  SETTING_DEVICE_TYPE_DETAILS: { path: '/device-images/:name/:visoImage' },
  PENDING_DEVICE_TYPE_LIST: { path: '/pending-images' },
  CREATE_DEVICE_IMAGE: { path: '/create/image' },
  LICENSE_DETAILS: { path: '/settings/license/:id' },
  IMAGE_DETAILS: { path: 'device/image/:id' },
  NODE_DETAILS: { path: '/device/:deviceId/node/:id' },
  SOLUTION_DETAILS: { path: '/solutions/:id' },
  CREATE_SOLUTION: { path: '/solution/create' },
  CREATE_SOLUTION_TYPE: { path: '/solution/create/:type' },
  LIST_SOLUTION: { path: '/solutions' },
  USER_INVITATION_LIST: { path: '/settings/invitation' },
  RESEND_EMAIL: { path: '/resend/email' },
  RESET_PASSWORD: { path: '/reset-password' },
  EXPERIMENTS: { path: '/experiments' },
  ANNOTATION_TAKS: { path: '/annotation-tasks' },
  LIBRARY_ANNOTATION_JOB: { path: '/annotation-jobs' },
  LIBRARY_ANNOTATION_TASK_CREATE: { path: '/annotation-tasks/create' },
  INCIDENT: { path: '/incident' },
};

export const AUTH_BASE = `${process.env.API_URL}/auth/graphql`;
