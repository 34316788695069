import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Slider, { Handle } from 'rc-slider';
import { DragIcon } from 'images/common';

import messages from 'utils/messages';
import classNames from 'classnames';

class SingleRange extends React.Component {
  state = {
    filterValue: [0]
  }

  onChange = (value) => {
    this.setState({ filterValue: value })
    this.props.onChange && this.props.onChange(value)
  }

  render() {
    const { title, customClass, color, disabled, onFilterApply, maxValue = 0, minValue = 0, isPercent, value, showLegend } = this.props;

    const customInputClass = `range-slider text-field text-field_nolabel text-field_${customClass}`;

    const inputClass = classNames({
      'text-field__range': true,
      'text-field__input': true,
      'text-field__input_filled': true,
    });

    const handleSliderClass = classNames('slider-icon', {
      [color]: color,
      disabled,
    });

    return (
      <div className={classNames(
        `text-field__range_container text-field__range_container_${customClass}`,
        isPercent && 'text-field__range_container--percent'
      )}>
        <div className="filters-bar__heading">{title}</div>
        <div className="filters-bar__range">
          <input
            type="text"
            maxLength={4}
            value={value ? value : this.state.filterValue}
            className={inputClass}
            onChange={e => this.onChange(e.target.value)}
          />
          {isPercent && <div className='filters-bar__heading filters-bar__heading--percent'>%</div>}
        </div>
        <div className={customInputClass}>
          <Slider
            handle={handleProps => (
              <Handle {...handleProps}>
                <div className={handleSliderClass}><DragIcon /></div>
              </Handle>
            )}
            onChange={val => this.onChange(val)}
            min={minValue}
            max={maxValue}
            value={[value ? value : this.state.filterValue]}
            defaultValue={[value ? value : this.state.filterValue]}
          />
        </div>
        {showLegend &&
          <div className='rc-slider-legend'>
            <div className='rc-slider-legend__item rc-slider-legend__item--min'>{minValue}{isPercent && '%'}</div>
            <div className='rc-slider-legend__item rc-slider-legend__item--max'>{maxValue}{isPercent && '%'}</div>
          </div>
        }
        {onFilterApply &&
          <>
            <hr className="mt_100" />
            <button type="button" className={`filters-bar__apply ${customClass}`} onClick={onFilterApply}>
              <FormattedMessage {...messages.apply} />
            </button>
          </>
        }
      </div>
    );
  }
}

SingleRange.propTypes = {
  name: PropTypes.string,
  isPercent: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onFilterApply: PropTypes.func,
  onChange: PropTypes.func,
  customClass: PropTypes.string,
};

export default SingleRange;
