import axios from 'axios';

import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import axiosObj from './config';

const WORKSPACE_API_URL = `${process.env.API_URL}/workspace/graphql`;
const SUBSCRIPTION_API_URL = `${process.env.API_URL}/subscription/graphql`;

const DEFAULT_WORKSPACE_FIELDS = {
  id: true,
  name: true,
  organizationName: true,
  organizationAddress: true,
  subDomain: true,
  logo: true,
  color: true,
  contrast: true,
  defaultLanguage: true,
  createdAt: true,
  updatedAt: true,
  status: true,
  // subscriptionInterval: true,
  grafanaOrgId: true,
  showGetStartedPanel: true,
  collapseSideBar: true,
  createdBy: {
    id: true,
    name: true
  },
  twoFactorSettingEnabled: true,
  builderInstalled: true,
  hasPaymentSource: true,
  annotationEnabled: true,
  trainingEnabled: true,
  datasetEnabled: true,
  sessionDuration: true,
  paymentTerms: true,
  plan: {
    id: true,
    name: true,
    status: true,
    features: {
      applicationLimit: true,
      applicationVersionLimit: true,
      dashboardLimit: true,
      deploymentLimit: true,
      deviceLimit: true,
      moduleLimit: true,
      profileLimit: true,
      videoLimit: true,
      userLimit: true,
      influxStorageLimit: true,
      logStorageLimit: true,
      auditLogStatus: true,
      appDataRetentionDays: true,
      deviceMetricsRetentionDays: true,
      multiFactorAuthenticationStatus: true,
      userRoleManagementStatus: true,
      terminalAccessManagementStatus: true,
      videoViewerAccessManagementStatus: true,
      forcedPasswordReset: true,
      sessionDuration: true,
    }
  },
  demoModeEnabled: true,
  incidentsEnabled: true,
  dashboardsEnabled: true,
  autoTrainingEnabled: true
};

const DEFAULT_CREATE_WORKSPACE_FIELDS = {
  id: true,
  name: true,
  organizationName: true,
  organizationAddress: true,
  subDomain: true,
  logo: true,
  color: true,
  contrast: true,
  defaultLanguage: true,
  createdAt: true,
  updatedAt: true,
  // subscriptionInterval: true,
  grafanaOrgId: true,
  showGetStartedPanel: true,
  collapseSideBar: true,
  createdBy: {
    id: true,
    name: true
  },
  currentApplicationCount: true,
  currentDashboardCount: true,
  currentDeviceCount: true,
  currentDeploymentCount: true,
  currentModuleCount: true,
  currentProfileCount: true,
  currentVideoCount: true,
  twoFactorSettingEnabled: true,
  plan: {
    id: true,
    name: true,
    status: true,
    features: {
      applicationLimit: true,
      applicationVersionLimit: true,
      dashboardLimit: true,
      deploymentLimit: true,
      deviceLimit: true,
      moduleLimit: true,
      profileLimit: true,
      videoLimit: true,
      userLimit: true,
      influxStorageLimit: true,
      logStorageLimit: true,
      auditLogStatus: true,
      appDataRetentionDays: true,
      deviceMetricsRetentionDays: true,
      multiFactorAuthenticationStatus: true,
      terminalAccessManagementStatus: true,
      videoViewerAccessManagementStatus: true,
    }
  }
};

const DEFAULT_PENDING_FIELDS = {
  id: true,
  name: true,
  organizationName: true,
  status: true,
};

const DEFAULT_DETAIL_WORKSPACE_FIELDS = {
  id: true,
  name: true,
  organizationName: true,
  organizationAddress: true,
  subDomain: true,
  logo: true,
  color: true,
  contrast: true,
  userCounts: true,
  status: true,
  defaultLanguage: true,
  createdAt: true,
  updatedAt: true,
  // subscriptionInterval: true,
  grafanaOrgId: true,
  showGetStartedPanel: true,
  collapseSideBar: true,
  createdBy: {
    id: true,
    name: true
  },
  twoFactorSettingEnabled: true,
  currentApplicationCount: true,
  currentDashboardCount: true,
  currentDeviceCount: true,
  currentDeploymentCount: true,
  currentModuleCount: true,
  currentProfileCount: true,
  currentVideoCount: true,
  subscriptionId: true,
  builderInstalled: true,
  hasPaymentSource: true,
  annotationEnabled: true,
  trainingEnabled: true,
  datasetEnabled: true,
  sessionDuration: true,
  paymentTerms: true,
  plan: {
    id: true,
    name: true,
    status: true,
    features: {
      applicationLimit: true,
      applicationVersionLimit: true,
      dashboardLimit: true,
      deploymentLimit: true,
      deviceLimit: true,
      moduleLimit: true,
      profileLimit: true,
      videoLimit: true,
      userLimit: true,
      influxStorageLimit: true,
      logStorageLimit: true,
      auditLogStatus: true,
      multiFactorAuthenticationStatus: true,
      appDataRetentionDays: true,
      deviceMetricsRetentionDays: true,
      userRoleManagementStatus: true,
      terminalAccessManagementStatus: true,
      videoViewerAccessManagementStatus: true,
      forcedPasswordReset: true,
      sessionDuration: true,
    }
  },
  demoModeEnabled: true,
  incidentsEnabled: true,
  dashboardsEnabled: true,
  modelTestingEnabled: true,
  autoTrainingEnabled: true,
  fiftyOneLocked: true
};

const DEFAULT_USER_WORKSPACE_FIELDS = {
  id: true,
  isNew: true,
  name: true,
  organizationName: true,
  subDomain: true,
  logo: true,
  userRole: true,
  status: true,
  twoFactorEnabled: true
};

const TASK_ACTOR = {
  id: true,
  firstName: true,
  lastName: true,
  email: true
};

const SETUP_TRACKER = {
  inviteFirstTeamMember: true,
  inviteFirstTeamMemberUpdatedBy: TASK_ACTOR,
  inviteFirstTeamMemberUpdatedAt: true,
  enrolFirstDevice: true,
  enrolFirstDeviceUpdatedBy: TASK_ACTOR,
  enrolFirstDeviceUpdatedAt: true,
  addFirstModule: true,
  addFirstModuleUpdatedBy: TASK_ACTOR,
  addFirstModuleUpdatedAt: true,
  createFirstApplication: true,
  createFirstApplicationUpdatedBy: TASK_ACTOR,
  createFirstApplicationUpdatedAt: true,
  deployFirstProfile: true,
  deployFirstProfileUpdatedBy: TASK_ACTOR,
  deployFirstProfileUpdatedAt: true,
  completedCount: true,
  completed: true,
  taskCount: true,
  createdAt: true,
  updatedAt: true,
  scheduleOnBoarding: true,
  scheduleOnBoardingUpdatedAt: true,
  scheduleOnBoardingUpdatedBy: TASK_ACTOR,
  knowLedgeCenter: true,
  knowLedgeCenterUpdatedBy: TASK_ACTOR,
  knowLedgeCenterUpdatedAt: true,
  installDemoApplication: true,
  installDemoApplicationUpdatedBy: TASK_ACTOR,
  installDemoApplicationUpdatedAt: true
};

const DEFAULT_WORKSPACES_FIELDS = {
  id: true,
  name: true,
  organizationName: true,
  subDomain: true,
  grafanaOrgId: true,
  contrast: true,
  userCounts: true,
  color: true,
  status: true,
  showGetStartedPanel: true,
  collapseSideBar: true,
  builderInstalled: true,
  hasPaymentSource: true,
  // subscriptionInterval: true,
  logo: true,
  deleteDate: true,
  createdBy: {
    id: true,
    name: true
  },
  twoFactorSettingEnabled: true,
  datasetEnabled: true,
  annotationEnabled: true,
  trainingEnabled: true,
  paymentTerms: true,
  plan: {
    id: true,
    name: true,
    status: true,
    features: {
      applicationLimit: true,
      applicationVersionLimit: true,
      dashboardLimit: true,
      deploymentLimit: true,
      deviceLimit: true,
      moduleLimit: true,
      profileLimit: true,
      videoLimit: true,
      userLimit: true,
      influxStorageLimit: true,
      logStorageLimit: true,
      auditLogStatus: true,
      multiFactorAuthenticationStatus: true,
      userRoleManagementStatus: true,
      terminalAccessManagementStatus: true,
      videoViewerAccessManagementStatus: true,
      forcedPasswordReset: true
    }
  },
  demoModeEnabled: true ,
  incidentsEnabled: true,
  dashboardsEnabled: true,
  modelTestingEnabled: true,
  autoTrainingEnabled: true,
  fiftyOneLocked: true
};

export function getWorkspace() {
  const query = {
    query: {
      workspace: {
        ...DEFAULT_WORKSPACES_FIELDS
      }
    }
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: WORKSPACE_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}


export function getWorkspacePending() {
  const query = {
    query: {
      pendingWorkspace: {
        ...DEFAULT_PENDING_FIELDS,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: WORKSPACE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}


export function validateWorkspaceName(param) {
  const query = {
    query: {
      validateWorkspaceName: {
        __args: param
      }
    }
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: WORKSPACE_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}

export function getWorkspaceDetail(param) {
  const query = {
    query: {
      workspaceDetail: {
        __args: param,
        ...DEFAULT_DETAIL_WORKSPACE_FIELDS
      }
    }
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: WORKSPACE_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}

export function uploadWorkspaceLogo(param, config) {
  const query = {
    mutation: {
      uploadWorkspaceLogo: {
        __args: param
      }
    }
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: WORKSPACE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
      ...config
    }
  });
}

export function deleteWorkspaceLogo(param) {
  const query = {
    mutation: {
      removeWorkspaceLogo: {
        __args: param
      }
    }
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: WORKSPACE_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}

export function scheduleWorkspaceDelete() {
  const query = {
    mutation: {
      cancelPlan: true
    }
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: SUBSCRIPTION_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}

export function updateWorkspaceLogo(param, config) {
  const query = {
    mutation: {
      updateWorkspaceLogo: {
        __args: param,
        ...DEFAULT_WORKSPACE_FIELDS
      }
    }
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: WORKSPACE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
      ...config
    }
  });
}

export function getWorkspaceLogo(data, config) {
  const formData = new FormData();

  data.fields
    && Object.keys(data.fields).forEach((item) => {
      formData.append(item, data.fields[item]);
    });

  formData.append('file', data.file);

  return axios.post(data.url, formData, {
    ...config
  });
}

export function getUserWorkspaces(param) {
  const query = {
    query: {
      userWorkspaces: {
        __args: param,
        ...DEFAULT_USER_WORKSPACE_FIELDS
      }
    }
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: WORKSPACE_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}

export function updateWorkspace(param) {
  const query = {
    mutation: {
      updateWorkspace: {
        __args: param,
        ...DEFAULT_WORKSPACE_FIELDS
      }
    }
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: WORKSPACE_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}

export function createWorkspace(param) {
  const query = {
    mutation: {
      createWorkspace: {
        __args: param,
        ...DEFAULT_CREATE_WORKSPACE_FIELDS
      }
    }
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: WORKSPACE_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}

export function workspaceSetupTrackerDetail() {
  const query = {
    query: {
      workspaceSetupTrackerDetail: {
        ...SETUP_TRACKER
      }
    }
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: WORKSPACE_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}

export function updateWorkspaceSetupTracker(param) {
  const query = {
    mutation: {
      updateWorkspaceSetupTracker: {
        __args: param,
        ...SETUP_TRACKER
      }
    }
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: WORKSPACE_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}


export function installDemoApp(param) {
  const query = {
    mutation: {
      installDemoApp: {
        __args: param,
      }
    }
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: WORKSPACE_API_URL,
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}


export function forcePasswordReset() {
  const query = {
    mutation: {
      forcePasswordReset: {
      }
    }
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: WORKSPACE_API_URL,
    data: {
      variables: {},
      query: gqlQuery,
    }
  });
}
