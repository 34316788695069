import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();
        const prevLocationRef = useRef(location.pathname); // Track previous location

        useEffect(() => {
            prevLocationRef.current = location.pathname;
        }, [location.pathname]);

        const forceRefresh = () => {
            props.router?.setRefreshKey?.(prevKey => prevKey + 1);
        };

        return (
            <Component {...props} router={{ location, navigate, params, forceRefresh }} />
        );
    }

    return ComponentWithRouterProp;
}
