import React from 'react';

export default () => (
  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_36521_3510)">
      <path d="M1.26393 15.4453H14.5311C15.3264 15.4453 15.7954 15.0353 15.7954 14.4325C15.7954 13.5953 14.9496 12.8421 14.2214 12.0971C13.6689 11.5196 13.5182 10.331 13.4596 9.36852C13.3929 6.15424 12.5475 3.93602 10.3125 3.13245C10.0029 2.04424 9.13214 1.17352 7.90179 1.17352C6.6625 1.17352 5.80071 2.04424 5.4825 3.13245C3.25607 3.93602 2.40214 6.15388 2.34357 9.36817C2.27679 10.331 2.13429 11.5196 1.57357 12.0971C0.853571 12.8421 0 13.5953 0 14.4328C0 15.0353 0.477143 15.4453 1.26393 15.4453ZM5.34857 16.5839C5.44929 17.806 6.47857 18.8267 7.90179 18.8267C9.31607 18.8267 10.3457 17.806 10.4546 16.5839H5.34857Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_36521_3510">
        <rect width="15.7954" height="19.6532" fill="white" transform="translate(0 0.173401)" />
      </clipPath>
    </defs>
  </svg>

);
