import { AUTH_BASE } from 'api/constants';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import axios from 'axios';
import axiosObj, { axiosInstance as unauthAxios } from './config';

const WORKSPACE_LOGIN = {
  workspaceSetupInvited: true,
  token: true,
  user: {
    id: true,
    email: true,
  },
  refreshToken: true,
}

const DEFAULT_USER_FIELDS = {
  id: true,
  firstName: true,
  lastName: true,
  status: true,
  tags: true,
  role: {
    id: true,
    name: true,
    usersCount: true,
    permissionUsers: true,
    permissionDevices: true,
    permissionOSUpdate: true,
    permissionGroups: true,
    permissionProfiles: true,
    permissionModules: true,
    permissionApplication: true,
    permissionRoles: true,
    permissionWorkspace: true,
    permissionBilling: true,
    permissionScopes: true,
    permissionDashboard: true,
    permissionDataset: true,
    permissionIncidents: true,

  },
  address1: true,
  address2: true,
  city: true,
  country: true,
  postCode: true,
  phoneNumber: true,
  createdAt: true,
  updatedAt: true,
  language: true,
  email: true,
  lastLoginAt: true,
};

const TWO_FACTOR_SETUP = {
  secret: true,
  recoveryKeys: true,
  qrCodeString: true,
};

export function obtainToken(data) {
  const loginQuery = {
    mutation: {
      login: {
        __args: {
          email: data.email,
          password: data.password,
          captchaToken: data.captchaToken,
        },
        token: true,
        email: true,
        workspaceSetupInvited: true,
        passwordResetRequired: true,
        isEmailConfirmed: true,
        workspaces: {
          id: true,
          logo: true,
          isNew: true,
          status: true,
          userRole: true,
          name: true,
          organizationName: true,
          subDomain: true,
          twoFactorEnabled: true,
        },
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(loginQuery, { pretty: true });

  const config = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery,
    },
  };

  return unauthAxios(config);
}

export function workspaceLogin(data) {
  const loginQuery = {
    mutation: {
      workspaceLogin: {
        __args: {
          email: data.email,
          password: data.password,
          subDomain: data.subDomain,
        },
        workspaceSetupInvited: true,
        token: true,
        user: {
          id: true,
          email: true,
        },
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(loginQuery, { pretty: true });

  const config = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery,
    },
  };

  return unauthAxios(config);
}

export function workspaceSelect(data) {
  const selectWorkspace = {
    mutation: {
      selectWorkspace: {
        __args: data,
        ...WORKSPACE_LOGIN
      }
    }
  }

  const gqlQuery = jsonToGraphQLQuery(selectWorkspace, { pretty: true });

  const config = {
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery,
    },
  };

  return axiosObj(config);
}

export function sendResetPassword(param) {
  const query = {
    mutation: {
      sendResetPassword: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return unauthAxios({
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function resetPassword(param) {
  const query = {
    mutation: {
      resetPassword: {
        __args: param,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  const config = {
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery,
    },
  };

  return axiosObj(config);
}

export function register(param) {
  const query = {
    mutation: {
      signup: {
        __args: param,
        token: true,
        email: true,
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return unauthAxios({
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function invitationSignup(param) {
  const query = {
    mutation: {
      invitationSignup: {
        __args: param,
        token: true,
        user: { ...DEFAULT_USER_FIELDS },
      },
    },
  };
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function twoFactorRecoveryKeys() {
  const query = {
    query: {
      twoFactorRecoveryKeys
    }
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function enableTwoFactor(param) {
  const query = {
    mutation: {
      enableTwoFactor: {
        __args: param,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export function initTwoFactorSetup() {
  const query = {
    mutation: {
      initTwoFactorSetup: {
        ...TWO_FACTOR_SETUP,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}


export function disableTwoFactor() {
  const query = {
    mutation: {
      disableTwoFactor
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery,
    },
  });
}

export async function getNRStatus(workspaceId) {
  const API_URL = process.env.API_URL.trim();
  let env = 'dev';

  if(API_URL.includes('staging')) {
    env = 'staging'
  }

  if(API_URL.includes('app')) {
    env = 'app'
  }

  if(API_URL.includes('dev')) {
    env = 'dev'
  }


  const result = await axios.get(`https://builder-${workspaceId}.${env}.viso.ai`);

  return result;
}

export async function getFiftyoneStatus(workspaceId) {
  const API_URL = process.env.API_URL.trim();
  let env = 'dev';

  if(API_URL.includes('staging')) {
    env = 'staging'
  }

  if(API_URL.includes('app')) {
    env = 'app'
  }

  if(API_URL.includes('dev')) {
    env = 'dev'
  }

  const result = await axios.get(`https://dataset-${workspaceId}.${env}.viso.ai/`);

  return result;
}
