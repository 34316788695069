import { fromJS } from "immutable";
import { LOGOUT } from "containers/App/constants";
import { FETCH_USER_WORKSPACE_REQUEST } from "containers/Settings/Workspace/constants";
import {
  CLEAR_FILE,
  GET_MODULE_REQUEST,
  GET_MODULE_SUCCESS,
  GET_MODULE_ERROR,
  LIST_LIBRARY_APPLICATIONS_ERROR,
  LIST_LIBRARY_APPLICATIONS_REQUEST,
  LIST_LIBRARY_APPLICATIONS_SUCCESS,
  LIST_LIBRARY_MODULES_ERROR,
  LIST_LIBRARY_MODULES_REQUEST,
  LIST_LIBRARY_MODULES_SUCCESS,
  UPDATE_MODULE_SUCCESS,
  GET_SSH_KEY_REQUEST,
  GET_SSH_KEY_SUCCESS,
  GET_SSH_KEY_ERROR,
  GET_APPLICATION_DETAIL_REQUEST,
  GET_APPLICATION_DETAIL_SUCCESS,
  GET_APPLICATION_DETAIL_ERROR,
  UPDATE_APPLICATION_REQUEST,
  UPDATE_APPLICATION_SUCCESS,
  UPDATE_APPLICATION_ERROR,
  CREATE_APPLICATION_ERROR,
  CREATE_APPLICATION_REQUEST,
  CREATE_APPLICATION_SUCCESS,
  LIST_COMMITS_ERROR,
  LIST_COMMITS_SUCCESS,
  LIST_COMMITS_REQUEST,
  NODE_RED_MODULES_REQUEST,
  NODE_RED_MODULES_SUCCESS,
  NODE_RED_MODULES_ERROR,
  NODE_RED_MODULE_REQUEST,
  NODE_RED_MODULE_SUCCESS,
  NODE_RED_MODULE_ERROR,
  GET_DOCKER_IMAGES_REQUEST,
  GET_DOCKER_IMAGES_SUCCESS,
  GET_DOCKER_IMAGES_ERROR,
  GET_AWS_ACCESS_KEY_REQUEST,
  GET_AWS_ACCESS_KEY_SUCCESS,
  GET_AWS_ACCESS_KEY_ERROR,
  GET_MODULE_VERSION_REQUEST,
  GET_MODULE_VERSION_SUCCESS,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERROR,
  UPLOAD_FILE_REQUEST,
  REMOVE_UPLOAD_MODULE_FILE,
  LIST_TEMPLATE_REQUEST,
  LIST_TEMPLATE_SUCCESS,
  LIST_TEMPLATE_ERROR,
  ADD_APPLICATION_FROM_TEMPLATE_REQUEST,
  ADD_APPLICATION_FROM_TEMPLATE_SUCCESS,
  ADD_APPLICATION_FROM_TEMPLATE_ERROR,
  IMPORT_FILE_APPLICATION_REQUEST,
  IMPORT_FILE_APPLICATION_SUCCESS,
  IMPORT_FILE_APPLICATION_ERROR,
  LIST_VIDEOS_REQUEST,
  LIST_VIDEOS_SUCCESS,
  ADD_VIDEO_FILE_REQUEST,
  ADD_VIDEO_FILE_SUCCESS,
  LIST_VIDEO_REQUEST,
  LIST_VIDEO_SUCCESS,
  UPDATE_VIDEO_SUCCESS,
  UPDATE_VIDEO_REQUEST,
  CREATE_PRIVATE_IMAGE_DOCKER_SUCCESS,
  CREATE_PRIVATE_IMAGE_DOCKER_REQUEST,
  CREATE_PRIVATE_IMAGE_DOCKER_ERROR,
  ADD_MODEL_FILE_REQUEST,
  LIST_LIBRARY_MODELS_SUCCESS,
  LIST_LIBRARY_MODELS_REQUEST,
  LIST_LIBRARY_RELATED_MODELS_REQUEST,
  LIST_LIBRARY_RELATED_MODELS_SUCCESS,
  LIST_MODEL_REQUEST,
  LIST_MODEL_SUCCESS,
  LIST_LIBRARY_ENVIRONMENTS_REQUEST,
  LIST_LIBRARY_ENVIRONMENTS_SUCCESS,
  LIST_LIBRARY_IMAGE_ANNOTATION_REQUEST,
  LIST_LIBRARY_IMAGE_ANNOTATION_SUCCESS,
  LIST_LIBRARY_MODEL_TRAINING_REQUEST,
  LIST_LIBRARY_MODEL_TRAINING_SUCCESS,
  LIST_LIBRARY_JOB_TRAINING_REQUEST,
  LIST_LIBRARY_JOB_TRAINING_SUCCESS,
  LIST_DATASET_REQUEST,
  LIST_DATASET_SUCCESS,
  LIST_DATASET_ERROR,
  DETAIL_DATASET_REQUEST,
  DETAIL_DATASET_SUCCESS,
  DETAIL_DATASET_ERROR,
  UPLOAD_FILES_REQUEST,
  UPLOAD_FILES_SUCCESS,
  UPLOAD_FILES_ERROR,
  LOADING_PARAMS,
  GET_EXPERIMENTS_REQUEST,
  GET_EXPERIMENTS_SUCCESS,
  GET_EXPERIMENTS_ERROR,
  DETAILS_LIBRARY_JOB_TRAINING_REQUEST,
  DETAILS_LIBRARY_JOB_TRAINING_SUCCESS,
  LIST_LIBRARY_ANNOTATION_TASK_REQUEST,
  LIST_LIBRARY_ANNOTATION_TASK_SUCCESS,
  LIST_LIBRARY_ANNOTATION_TASK_ERROR,
  LIST_LIBRARY_ANNOTATION_JOB_REQUEST,
  LIST_LIBRARY_ANNOTATION_JOB_SUCCESS,
  LIST_LIBRARY_ANNOTATION_JOB_ERROR,
} from "./constants";

export const initialState = fromJS({
  flows: {
    errors: null,
    loading: false,
    count: 0,
    data: [],
  },
  noderedModules: [],
  file: {
    uploading: false,
    url: "",
  },
  files: {
    url: [],
    uploading: false,
  },
  moduleVersions: [],
  modules: {
    errors: null,
    loading: false,
    data: [],
    count: 0,
  },
  models: {
    errors: null,
    loading: false,
    data: [],
    details: "",
    count: 0,
    relatedModel: []
  },
  videos: {
    errors: null,
    loading: false,
    data: [],
    details: {},
    count: 0,
  },
  module: {
    noderedModule: {},
    dependencies: {
      data: [],
      count: 0,
    },
    versions: [],
    data: [],
    errors: null,
    loading: false,
  },
  experiments: { 
    errors: null,
    loading: false,
    data: [],
    details: "",
    count: 0,
  },
  annotationJob: {
    errors: null,
    loading: false,
    data: [],
    details: "",
    count: 0,
  },
  modelTraining: {
    errors: null,
    loading: false,
    data: [],
    details: "",
    count: 0,
  },
  jobTraining: {
    errors: null,
    loading: false,
    data: [],
    details: "",
    count: 0,
  },
  annotationTask: {
    errors: null,
    loading: false,
    data: [],
    details: "",
    count: 0,
  },
  imageAnnotation: {
    errors: null,
    loading: false,
    data: [],
    details: "",
    count: 0,
  },
  sshKeys: [],
  awsAccessKey: {},
  flow: {
    data: null,
    errors: null,
    commits: [],
    loading: false,
  },
  template: {
    details: null,
    errors: null,
    data: [],
    loading: false,
  },
  privateDockerImage: {},
  errors: null,
  loading: false,
  dataset: {
    errors: null,
    loading: false,
    data: [],
    details: "",
    count: 0,
  },
  loadingParams: {}
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT:
      return initialState;
    case LOADING_PARAMS:

      return state.merge({
        loadingParams: {
          ...state.toJS().loadingParams,
          ...action.payload
        }
      })
    case GET_AWS_ACCESS_KEY_REQUEST:
      return state.merge({
        awsAccessKey: {},
      });
    case CREATE_PRIVATE_IMAGE_DOCKER_REQUEST:
      return state.merge({
        loading: true,
        privateDockerImage: {},
      });
    case CREATE_PRIVATE_IMAGE_DOCKER_SUCCESS:
      return state.merge({
        loading: false,
        privateDockerImage: action.payload,
      });
    case CREATE_PRIVATE_IMAGE_DOCKER_ERROR:
      return state.merge({
        loading: false,
      });
    case GET_AWS_ACCESS_KEY_SUCCESS:
      return state.merge({
        awsAccessKey: action.payload,
      });
    case GET_AWS_ACCESS_KEY_ERROR:
      return state.merge({
        awsAccessKey: {},
      });
    case GET_SSH_KEY_REQUEST:
      return state.merge({
        sshKeys: [],
      });
    case GET_SSH_KEY_SUCCESS:
      return state.merge({
        sshKeys: action.payload,
      });
    case GET_SSH_KEY_ERROR:
      return state.merge({
        sshKeys: [],
      });
    case GET_EXPERIMENTS_REQUEST:
      return state.merge({
        experiments: {
          errors: null,
          loading: true,
          data: [],
          details: "",
          count: 0,
        }
      });
    case GET_EXPERIMENTS_SUCCESS:
      return state.merge({
        experiments: {
          ...state.toJS().experiments,
          loading: false,
          data: action.payload.data,
          count: action.payload.count
        },
      });
    case GET_EXPERIMENTS_ERROR:
      return state.merge({
        experiments: {
          errors: null,
          loading: false,
          data: [],
          details: "",
          count: 0,
        }
      });
    
      case LIST_LIBRARY_ANNOTATION_TASK_REQUEST:
        return state.merge({
          annotationTask: {
            errors: null,
            loading: true,
            data: [],
            details: "",
            count: 0,
          }
        });
      case LIST_LIBRARY_ANNOTATION_TASK_SUCCESS:
        return state.merge({
          annotationTask: {
            ...state.toJS().annotationTask,
            loading: false,
            data: action.payload.data,
            count: action.payload.count
          },
        });
      case LIST_LIBRARY_ANNOTATION_TASK_ERROR:
        return state.merge({
          annotationTask: {
            errors: null,
            loading: false,
            data: [],
            details: "",
            count: 0,
          }
        });
      
            
      case LIST_LIBRARY_ANNOTATION_JOB_REQUEST:
        return state.merge({
          annotationJob: {
            errors: null,
            loading: true,
            data: [],
            details: "",
            count: 0,
          }
        });
      case LIST_LIBRARY_ANNOTATION_JOB_SUCCESS:
        return state.merge({
          annotationJob: {
            ...state.toJS().annotationJob,
            loading: false,
            data: action.payload.data,
            count: action.payload.count
          },
        });
      case LIST_LIBRARY_ANNOTATION_JOB_ERROR:
        return state.merge({
          annotationJob: {
            errors: null,
            loading: false,
            data: [],
            details: "",
            count: 0,
          }
        });
      
      case NODE_RED_MODULES_REQUEST:
      return state.merge({
        noderedModules: [],
      });
    case NODE_RED_MODULES_SUCCESS:
      return state.merge({
        noderedModules: action.payload,
      });
    case NODE_RED_MODULES_ERROR:
      return state.merge({
        noderedModules: [],
      });
    case LIST_COMMITS_REQUEST:
      return state.merge({
        flow: {
          ...state.toJS().flow,
          commits: [],
        },
      });
    case LIST_COMMITS_SUCCESS:
      return state.merge({
        flow: {
          ...state.toJS().flow,
          commits: action.payload,
        },
      });
    case GET_MODULE_VERSION_REQUEST:
      return state.merge({
        moduleVersions: [],
      });
    case GET_MODULE_VERSION_SUCCESS:
      return state.merge({
        moduleVersions: action.payload,
      });
    case LIST_COMMITS_ERROR:
      return state.merge({
        flow: {
          ...state.toJS().flow,
          commits: [],
        },
      });

    case NODE_RED_MODULE_REQUEST:
      return state.merge({
        module: {
          ...state.toJS().module,
          noderedModule: {},
        },
      });
    case NODE_RED_MODULE_SUCCESS:
      return state.merge({
        module: {
          ...state.toJS().module,
          noderedModule: action.payload,
        },
      });
    case NODE_RED_MODULE_ERROR:
      return state.merge({
        module: {
          ...state.toJS().module,
          noderedModule: {},
        },
      });

    case GET_DOCKER_IMAGES_REQUEST:
      return state.merge({
        module: {
          ...state.toJS().module,
          dependencies: {
            data: [],
            count: 0,
          },
        },
      });
    case GET_DOCKER_IMAGES_SUCCESS:
      return state.merge({
        module: {
          ...state.toJS().module,
          dependencies: {
            data: action.payload,
            count: action.payload.length,
          },
        },
      });
    case GET_DOCKER_IMAGES_ERROR:
      return state.merge({
        module: {
          ...state.toJS().module,
          dependencies: {
            data: [],
            count: 0,
          },
        },
      });

    case LIST_LIBRARY_APPLICATIONS_REQUEST:
      return state.merge({
        flows: {
          errors: null,
          loading: true,
          data: state.toJS().flows.data,
          count: 0,
        },
      });

    case LIST_LIBRARY_APPLICATIONS_SUCCESS:
      return state.merge({
        flows: {
          errors: null,
          loading: false,
          data: action.payload,
          count: action.payload.length,
        },
      });
    case LIST_LIBRARY_APPLICATIONS_ERROR:
      return state.set({
        flows: {
          errors: null,
          loading: false,
          data: [],
          count: 0,
        },
      });
    case FETCH_USER_WORKSPACE_REQUEST:
      return state.merge({
        flows: {
          errors: null,
          loading: false,
          data: [],
          count: 0,
        },
        modules: {
          loading: false,
          data: [],
          errors: null,
          count: 0,
        },
        videos: {
          errors: null,
          loading: false,
          data: [],
          details: {},
          count: 0,
        },
      });
    case LIST_LIBRARY_MODULES_REQUEST:
      return state.merge({
        modules: {
          loading: true,
          data: state.toJS().modules.data,
          errors: null,
        },
      });
    case LIST_LIBRARY_MODULES_SUCCESS:
      return state.merge({
        modules: {
          errors: null,
          loading: false,
          data: action.payload,
          count: action.payload.length,
        },
      });

    case LIST_LIBRARY_MODELS_REQUEST:
      return state.merge({
        models: {
          loading: true,
          relatedModel: [],
          data: state.toJS().models.data,
          errors: null,
        },
      });
    case LIST_LIBRARY_MODELS_SUCCESS:
      return state.merge({
        models: {
          errors: null,
          loading: false,
          data: action.payload,
          count: action.payload.length,
          relatedModel: [],
        },
      });

      case LIST_LIBRARY_RELATED_MODELS_REQUEST:
        return state.merge({
          models: {
            loading: true,
            data: state.toJS().models.data,
            relatedModel: [],
            errors: null,
          },
        });
      case LIST_LIBRARY_RELATED_MODELS_SUCCESS:
        return state.merge({
          models: {
            errors: null,
            loading: false,
            data: state.toJS().models.data,
            relatedModel: action.payload,
            count: action.payload.length,
          },
        });

    case LIST_MODEL_REQUEST:
      return state.merge({
        models: {
          loading: true,
          details: "",
          data: [],
          relatedModel: [],
        },
      });
    case LIST_MODEL_SUCCESS:
      return state.merge({
        models: {
          ...state.models,
          errors: null,
          loading: false,
          data: [],
          relatedModel: [],
          details: action.payload,
        },
      });

    case LIST_VIDEOS_REQUEST:
      return state.merge({
        videos: {
          errors: null,
          loading: true,
          data: state.toJS().videos.data,
          count: 0,
        },
      });
    case LIST_VIDEOS_SUCCESS:
      return state.merge({
        videos: {
          loading: false,
          count: action.payload.length,
          data: action.payload,
          errors: null,
        },
      });
    case UPDATE_VIDEO_REQUEST:
    case LIST_VIDEO_REQUEST:
      return state.set({
        videos: {
          errors: null,
          loading: true,
          data: [],
          details: action.payload,
        },
      });
    case UPDATE_VIDEO_SUCCESS:
    case LIST_VIDEO_SUCCESS:
      return state.merge({
        videos: {
          loading: false,
          data: [],
          details: action.payload,
          errors: null,
        },
      });
    case LIST_LIBRARY_MODULES_ERROR:
      return state.merge({
        modules: {
          errors: null,
          loading: false,
          data: [],
          count: 0,
        },
      });

    case GET_MODULE_REQUEST:
      return state.merge({
        module: {
          ...(state.toJS().module || []),
          data: null,
          errors: null,
          loading: true,
        },
      });
    case GET_MODULE_SUCCESS:
      return state.merge({
        module: {
          ...state.toJS().module,
          data: action.payload,
          errors: null,
          loading: false,
        },
      });
    case GET_MODULE_ERROR:
      return state.merge({
        module: {
          ...state.toJS().module,
          errors: action.payload,
          loading: false,
          data: null,
        },
      });
    case UPDATE_MODULE_SUCCESS:
      return state.merge({
        module: {
          ...state.toJS().module,
          data: action.payload,
          errors: null,
          loading: false,
        },
      });

    case GET_APPLICATION_DETAIL_REQUEST:
      return state.merge({
        flow: {
          ...state.toJS().flow,
          data: null,
          errors: null,
          loading: true,
        },
      });
    case GET_APPLICATION_DETAIL_SUCCESS:
      return state.merge({
        flow: {
          ...state.toJS().flow,
          data: action.payload,
          loading: false,
          errors: null,
        },
      });
    case GET_APPLICATION_DETAIL_ERROR:
      return state.merge({
        flow: {
          data: null,
          loading: false,
          errors: action.payload,
        },
      });

    case CREATE_APPLICATION_REQUEST:
      return state.merge({
        flow: {
          data: null,
          loading: false,
          errors: null,
        },
      });

    case CREATE_APPLICATION_SUCCESS:
      return state.merge({
        flow: {
          data: action.payload,
          loading: false,
          errors: null,
        },
      });

    case CREATE_APPLICATION_ERROR:
      return state.set({
        flow: {
          data: null,
          loading: false,
          errors: action.payload,
        },
      });

    case UPDATE_APPLICATION_REQUEST:
      return state.merge({
        flow: {
          data: null,
          loading: true,
          errors: null,
        },
      });

    case UPDATE_APPLICATION_SUCCESS:
      return state.merge({
        flow: {
          data: action.payload,
          loading: false,
          errors: null,
        },
      });

    case UPDATE_APPLICATION_ERROR:
      return state.set({
        flow: {
          data: null,
          loading: false,
          errors: action.payload,
        },
      });

    case CLEAR_FILE:
      return state.merge({
        file: {
          url: "",
          uploading: false,
        },
        files: {
          url: [],
          uploading: false,
        },
      });

    case ADD_VIDEO_FILE_REQUEST:
    case IMPORT_FILE_APPLICATION_REQUEST:
    case UPLOAD_FILE_REQUEST:
    case ADD_MODEL_FILE_REQUEST:
      return state.merge({
        file: {
          url: "",
          uploading: true,
        },
      });

    case ADD_VIDEO_FILE_SUCCESS:
    case IMPORT_FILE_APPLICATION_SUCCESS:
    case UPLOAD_FILE_SUCCESS:
      return state.merge({
        file: {
          url: action.payload,
          uploading: false,
        },
      });

    case IMPORT_FILE_APPLICATION_ERROR:
    case UPLOAD_FILE_ERROR:
      return state.merge({
        file: {
          url: "",
          uploading: false,
        },
      });

    case UPLOAD_FILES_REQUEST:
      return state.merge({
        files: {
          url: [],
          uploading: true,
        },
      });

    case UPLOAD_FILES_SUCCESS:
      return state.merge({
        files: {
          url: action.payload,
          uploading: false,
        },
      });

    case UPLOAD_FILES_ERROR:
      return state.merge({
        files: {
          url: [],
          uploading: false,
        },
      });

    case REMOVE_UPLOAD_MODULE_FILE:
      return state.merge({
        file: {
          url: "",
          uploading: false,
        },
      });

    case LIST_TEMPLATE_REQUEST:
      return state.merge({
        template: {
          loading: true,
          data: [],
          errors: null,
        },
      });
    case LIST_TEMPLATE_SUCCESS:
      return state.merge({
        template: {
          errors: null,
          loading: false,
          data: action.payload,
          count: action.payload.length,
        },
      });
    case LIST_TEMPLATE_ERROR:
      return state.merge({
        template: {
          errors: null,
          loading: false,
          data: [],
          count: 0,
        },
      });
    case ADD_APPLICATION_FROM_TEMPLATE_REQUEST:
      return state.merge({
        template: {
          ...state.toJS().template,
          errors: null,
          loading: true,
        },
      });
    case ADD_APPLICATION_FROM_TEMPLATE_SUCCESS:
      return state.merge({
        template: {
          ...state.toJS().template,
          errors: null,
          loading: false,
        },
      });
    case ADD_APPLICATION_FROM_TEMPLATE_ERROR:
      return state.merge({
        template: {
          ...state.toJS().template,
          errors: null,
          loading: false,
        },
      });

    case LIST_LIBRARY_IMAGE_ANNOTATION_REQUEST:
      return state.merge({
        imageAnnotation: {
          loading: true,
          details: "",
          data: state.toJS().imageAnnotation.data,
        },
      });
    case LIST_LIBRARY_IMAGE_ANNOTATION_SUCCESS:
      return state.merge({
        imageAnnotation: {
          ...state.imageAnnotation,
          errors: null,
          loading: false,
          data: action.payload,
          details: "",
        },
      });

    case LIST_LIBRARY_MODEL_TRAINING_REQUEST:
      return state.merge({
        modelTraining: {
          loading: true,
          details: "",
          data: state.toJS().modelTraining.data,
        },
      });
    case LIST_LIBRARY_MODEL_TRAINING_SUCCESS:
      return state.merge({
        modelTraining: {
          ...state.modelTraining,
          errors: null,
          loading: false,
          data: action.payload,
          details: "",
        },
      });


    case LIST_LIBRARY_JOB_TRAINING_REQUEST:
      return state.merge({
        jobTraining: {
          loading: true,
          data: state.toJS().jobTraining.data,
          details: state.toJS().jobTraining.details
        },
      });
    case LIST_LIBRARY_JOB_TRAINING_SUCCESS:
      return state.merge({
        jobTraining: {
          ...state.jobTraining,
          errors: null,
          loading: false,
          data: action.payload.data,
          count: action.payload.count,
          details: state.toJS().jobTraining.details
        },
      });

      case DETAILS_LIBRARY_JOB_TRAINING_REQUEST:
        return state.merge({
          jobTraining: {
            ...state.toJS().jobTraining,
            loading: true,
            details: "",
          },
        });
      case DETAILS_LIBRARY_JOB_TRAINING_SUCCESS:
        return state.merge({
          jobTraining: {
            ...state.toJS().jobTraining,
            errors: null,
            loading: false,
            details: action.payload,
          },
        });

    case LIST_DATASET_REQUEST:
      return state.merge({
        dataset: {
          loading: true,
          data: state.toJS().dataset.data,
          errors: null,
        },
      });
    case LIST_DATASET_SUCCESS:
      return state.merge({
        dataset: {
          errors: null,
          loading: false,
          data: action.payload,
          count: action.payload.length,
        },
      });
    case LIST_DATASET_ERROR:
      return state.merge({
        dataset: {
          errors: null,
          loading: false,
          data: [],
          count: 0,
        },
      });

    case DETAIL_DATASET_REQUEST:
      return state.merge({
        ...state.toJS().dataset,

        dataset: {
          loading: true,
          details: null,
          errors: null,
          data: [],
        },
      });
    case DETAIL_DATASET_SUCCESS:
      return state.merge({
        ...state.toJS().dataset,

        dataset: {
          loading: false,
          details: action.payload,
          data: [],
        },
      });
    case DETAIL_DATASET_ERROR:
      return state.merge({
        ...state.toJS().dataset,

        dataset: {
          errors: action.payload.errors,
          loading: false,
          data: [],
        },
      });
    default:
      return state;
  }
}

export default reducer;
