import axios from 'axios'

import { AUTH_BASE } from 'api/constants'
import axiosObj from './config'
import { jsonToGraphQLQuery } from 'json-to-graphql-query'

const DEFAULT_ME_FIELDS = {
  id: true,
  firstName: true,
  lastName: true,
  phoneNumber: true,
  address1: true,
  address2: true,
  city: true,
  country: true,
  postCode: true,
  tags: true,
  status: true,
  email: true,
  roleAssignedAt: true,
  role: {
    id: true,
    type: true,
    name: true,
    level: true,
    usersCount: true,
    permissionUsers: true,
    permissionDevices: true,
    permissionGroups: true,
    permissionProfiles: true,
    permissionModules: true,
    permissionApplication: true,
    permissionRoles: true,
    permissionWorkspace: true,
    permissionBilling: true,
    permissionScopes: true,
    permissionDashboard: true,
    permissionDataCollection: true,
    permissionImageAnnotation: true,
    permissionModelTraining: true,
    permissionAIModel: true,
    permissionDataset: true,
    permissionOSUpdate: true,
    permissionTerminalAccessManage: true, // will be used for terminal access
    permissionVideoViewerManage: true, // will be used for video viewer
    permissionWorkspaceHistory: true,
    permissionIncidents: true,

  },
  createdAt: true,
  updatedAt: true,
  language: true,
  workspaceId: true,
  lastLoginAt: true,
  terminalAccess: true,
  videoViewerAccess: true,
  twoFactorEnabled: true,
  workspaceSetupInvited : true
}


const DEFAULT_USER_FIELDS = {
  id: true,
  firstName: true,
  lastName: true,
  email: true,
  phoneNumber: true,
  tags: true,
  status: true,
  role: {
    id: true,
    name: true,
    level: true,
  },
  groups: {
    id: true,
    name: true,
    usersCount: true
  },
  address1: true,
  address2: true,
  city: true,
  country: true,
  postCode: true,
  roleAssignedAt: true,
  createdAt: true,
  updatedAt: true,
  language: true,
  lastLoginAt: true
}

const DEFAULT_USERS_FIELDS = {
  id: true,
  firstName: true,
  lastName: true,
  status: true,
  email: true,
  tags: true,
  role: {
    id: true,
    name: true,
    level: true,
  },
  videoViewerAccess: true,
  terminalAccess: true,
  lastLoginAt: true,
  updatedAt: true,
  twoFactorEnabled: true,
  demoData: true,
}

const DEFAULT_WORKSPACE_HISTORY_FIELDS = {
  username: true,
  email: true,
  event: true,
  ip: true,
  action: true,
  time: true
}

const DEFAULT_INVITATION_FIELDS = {
  email: true,
  status: true,
  invitedBy: {
    id: true,
    name: true
  },
  createdAt: true,
  expiresAt: true,
  id: true,
  userProfileId: true
}

const DEFAULT_USER_GROUP_FIELDS = {
  id: true,
  name: true,
  tags: true,
  description: true,
  createdAt: true,
  updatedAt: true,
  createdBy: {
    id: true,
    name: true
  },
  updatedBy: {
    id: true,
    name: true
  },
  usersCount: true
}

const INVITE_USER_DATA = {
  email: true,
  inviteType: true,
  workspace: {
    id: true,
    name: true,
    organizationName: true,
    // defaultLanguage: true,
    subDomain: true
  },
  isRegistered: true,
}

const BULK_ACTION = {
  message: true,
  skippedCount: true,
}

export function queryHandler(data) {
  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: data
    }
  })
}

export function getUsers(param) {
  const query = {
    query: {
      users: {
        __args: param,
        count: true,
        data: DEFAULT_USERS_FIELDS
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function getInvitedUsers(param) {
  const query = {
    query: {
      invitations: {
        __args: param,
        count: true,
        data: DEFAULT_INVITATION_FIELDS
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function cancelInvitation(param) {
  const query = {
    mutation: {
      cancelInvitation: {
        __args: param,
        ...DEFAULT_INVITATION_FIELDS
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function cancelInvitations(param) {
  const query = {
    mutation: {
      cancelInvitations: {
        __args: param,
        ...BULK_ACTION
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function resendInvitation(param) {
  const query = {
    mutation: {
      resendInvitation: {
        __args: param,
        ...DEFAULT_INVITATION_FIELDS
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function resendInvitations(param) {
  const query = {
    mutation: {
      resendInvitations: {
        __args: param,
        ...BULK_ACTION
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function me() {
  const query = {
    query: {
      me: Object.assign(DEFAULT_ME_FIELDS, { language: true })
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}


export function newWorkspaceMe() {
  const query = {
    query: {
      me: Object.assign(DEFAULT_USER_FIELDS, { language: true })
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  const token = localStorage.getItem('newAccessToken')
  const accessToken = localStorage.getItem('accessToken')

  return axios({
    method: 'post',
    url: AUTH_BASE,
    headers: {
      'Content-Type': 'application/json',
      'authorization': token ? `Bearer ${token}` : `Bearer ${accessToken}`
    },
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}


export function getUser(param) {

  const query = {
    query: {
      user: {
        __args: param,
        ...DEFAULT_USER_FIELDS
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function changePassword(param) {
  const query = {
    mutation: {
      changePassword: {
        __args: param
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function updateUser(param) {
  const query = {
    mutation: {
      updateUser: {
        __args: param,
        ...DEFAULT_USER_FIELDS
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function updateProfile(param) {
  const query = {
    mutation: {
      updateProfile: {
        __args: param,
        ...DEFAULT_USER_FIELDS
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function deactivateUser(param) {
  const query = {
    mutation: {
      deactivateUser: {
        __args: param,
        ...DEFAULT_USER_FIELDS
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function deleteUser(param) {
  const query = {
    mutation: {
      deleteUser: {
        __args: param,
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function deactivateUsers(param) {
  const query = {
    mutation: {
      deactivateUsers: {
        __args: param,
        ...BULK_ACTION
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function activateUser(param) {
  const query = {
    mutation: {
      activateUser: {
        __args: param,
        ...DEFAULT_USER_FIELDS
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function activateUsers(param) {
  const query = {
    mutation: {
      activateUsers: {
        __args: param,
        ...BULK_ACTION
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function archiveUser(param) {
  const query = {
    mutation: {
      archiveUser: {
        __args: param,
        ...DEFAULT_USER_FIELDS
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function archiveUsers(param) {
  const query = {
    mutation: {
      archiveUsers: {
        __args: param,
        ...BULK_ACTION
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function unarchiveUser(param) {
  const query = {
    mutation: {
      unarchiveUser: {
        __args: param,
        ...DEFAULT_USER_FIELDS
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function inviteUser(param) {
  const query = {
    mutation: {
      inviteUsers: {
        __args: param,
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function inviteData(param) {
  const query = {
    query: {
      inviteData: {
        __args: param,
        ...INVITE_USER_DATA
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function verifyEmail(param) {
  const query = {
    query: {
      verifyEmail: {
        __args: param
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axios({
    method: 'post',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}


export function resendEmail(param) {
  const query = {
    query: {
      resendEmailVerification: {
        __args: param
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axios({
    method: 'post',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  });
}

export function listUserGroups(args) {
  const query = {
    query: {
      userGroups: {
        __args: args,
        ...DEFAULT_USER_GROUP_FIELDS
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function getUserGroup(args) {
  const query = {
    query: {
      userGroup: {
        __args: args,
        ...DEFAULT_USER_GROUP_FIELDS
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function addUserGroup(param) {
  const query = {
    mutation: {
      createUserGroup: {
        __args: param,
        ...DEFAULT_USER_GROUP_FIELDS
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function updateUserGroup(param) {
  const query = {
    mutation: {
      updateUserGroup: {
        __args: param,
        ...DEFAULT_USER_GROUP_FIELDS
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function removeUserGroup(param) {
  const query = {
    mutation: {
      deleteUserGroup: {
        __args: param
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function updateTerminalAccess(param) {
  const query = {
    mutation: {
      updateTerminalAccess: {
        __args: param
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function updateVideoViewerAccess(param) {
  const query = {
    mutation: {
      updateVideoViewerAccess: {
        __args: param
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}

export function getWorkspaceHistory(param) {
  const query = {
    query: {
      workspaceHistory: {
        __args: param,
        count: true,
        data: DEFAULT_WORKSPACE_HISTORY_FIELDS
      }
    }
  }
  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true })

  return axiosObj({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery
    }
  })
}