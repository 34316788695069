import DetailScroll from 'components/Detail/Scroll';
import TopNav from 'containers/TopNav';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { routeMapping } from 'routes';
import {
  DEFAULT_ACCENT, LIBRARY_ACCENT, SOLUTION_ACCENT,
} from 'utils/constants';
import appMessages from 'utils/messages';
import SettingBox from 'containers/Settings/SettingBox';

class Settings extends React.Component {
  state = {
    showPurchaseConfirmation: false,
    showPaymentModal: false,
    paymentMethodId: '',
    showAuthenticationPage: false,
  };

  componentDidMount() {
  }

  render() {
    const { workspace } = this.props;

    return (
      <>
        <TopNav title={<FormattedMessage {...appMessages.settings} />} />
        <DetailScroll>
          <nav className="head-nav">
            <button className="round-button round-button_tl round-button_burger">
              <span className="round-button__label">
                <FormattedMessage {...appMessages.menu} />
              </span>
            </button>
          </nav>

          <div className="headline">
            <h1 className="headline__title heading__loading">
              <FormattedMessage {...appMessages.settings} />
            </h1>
          </div>

          <div className="controls">
            <div className="controls__row">
              <div className="grid-row">
                <div className="no-grid-col card_loading">
                  <div className="controls__title">
                    <h4>
                      <FormattedMessage {...appMessages.myViso} />
                    </h4>
                  </div>
                </div>
                <div className="grid-col grid-col_10 grid-col_sm-8 grid-col_xs-12">
                  <div className="grid-row">
                    <SettingBox
                      subDomain={workspace && workspace.name}
                      dataCy="my-profile"
                      customClass={`card_loading controls__item_${DEFAULT_ACCENT}`}
                      img="personalUser"
                      name={<FormattedMessage {...appMessages.myProfile} />}
                      description={(
                        <FormattedMessage
                          {...appMessages.manageYourInformationAndSettings}
                        />
                      )}
                    />
                    <SettingBox
                      dataCy="my-workspace"
                      subDomain={workspace && workspace.name}
                      customClass={`card_loading controls__item_${DEFAULT_ACCENT}`}
                      img="myWorkspace"
                      name={<FormattedMessage {...appMessages.myWorkspaces} />}
                      description={(
                        <FormattedMessage
                          {...appMessages.manageYourWorkspaces}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="controls__row">
              <div className="grid-row">
                <div className="no-grid-col card_loading">
                  <div className="controls__title">
                    <h4>
                      <FormattedMessage {...appMessages.security} />
                    </h4>
                  </div>
                </div>

                <div className="grid-col grid-col_10 grid-col_sm-8 grid-col_xs-12">
                  <div className="grid-row">
                    <SettingBox
                      dataCy="my-user"
                      subDomain={workspace && workspace.name}
                      customClass={`card_loading controls__item_${SOLUTION_ACCENT}`}
                      link={routeMapping.SETTINGS_USER.path}
                      img="users"
                      name={
                        <FormattedMessage {...appMessages.userManagement} />
                      }
                      description={(
                        <FormattedMessage
                          {...appMessages.createAndManageTheUsers}
                        />
                      )}
                    />
                    <SettingBox
                      subDomain={workspace && workspace.name}
                      customClass={`card_loading controls__item_${SOLUTION_ACCENT}`}
                      img="roles"
                      name={
                        <FormattedMessage {...appMessages.rolesAndAccess} />
                      }
                      description={(
                        <FormattedMessage
                          {...appMessages.manageUserRoles}
                        />
                      )}
                    />

                    <SettingBox
                      subDomain={workspace && workspace.name}
                      customClass={`card_loading controls__item_${SOLUTION_ACCENT}`}
                      img="roles"
                      name={
                        <FormattedMessage {...appMessages.authentication} />
                      }
                      description={(
                        <FormattedMessage
                          {...appMessages.manageWorkspaceWide}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="no-grid-col">
                  <div className="controls__title" />
                </div>
                <div className="grid-col grid-col_10 grid-col_sm-8 grid-col_xs-12">
                  <div className="grid-row">
                    <SettingBox
                      subDomain={workspace && workspace.name}
                      customClass={`card_loading controls__item_${SOLUTION_ACCENT}`}
                      img="videoAccess"
                      name={
                        <FormattedMessage {...appMessages.videoViewerAccess} />
                      }
                      description={(
                        <FormattedMessage
                          {...appMessages.manageVideo}
                        />
                      )}
                    />
                    <SettingBox
                      subDomain={workspace && workspace.name}
                      customClass={`card_loading controls__item_${SOLUTION_ACCENT}`}
                      img="terminalAccess"
                      name={
                        <FormattedMessage {...appMessages.terminalAccess} />
                      }
                      description={(
                        <FormattedMessage
                          {...appMessages.manageTerminal}
                        />
                      )}
                    />
                    <SettingBox
                      subDomain={workspace && workspace.name}
                      customClass={`card_loading controls__item_${SOLUTION_ACCENT}`}
                      img="auditLog"
                      name={
                        <FormattedMessage {...appMessages.auditLog} />
                      }
                      description={
                        <FormattedMessage {...appMessages.manageAuditLog} />
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="controls__row">
              <div className="grid-row">
                <div className="no-grid-col card_loading">
                  <div className="controls__title">
                    <h4>
                      <FormattedMessage {...appMessages.account} />
                    </h4>
                  </div>
                </div>

                <div className="grid-col grid-col_10 grid-col_sm-8 grid-col_xs-12">
                  <div className="grid-row">
                    <SettingBox
                      subDomain={workspace && workspace.name}
                      customClass={`card_loading controls__item_${LIBRARY_ACCENT}`}
                      img="workspace"
                      name={(
                        <FormattedMessage
                          {...appMessages.workspaceSettings}
                        />
                      )}
                      description={(
                        <FormattedMessage
                          {...appMessages.manageWorkspaceInformation}
                        />
                      )}
                    />
                    <SettingBox
                      subDomain={workspace && workspace.name}
                      customClass={`card_loading controls__item_${LIBRARY_ACCENT}`}
                      dataCy="billing-settings"
                      img="billing"
                      name={(
                        <FormattedMessage
                          {...appMessages.billingAndSubscriptions}
                        />
                      )}
                      description={(
                        <FormattedMessage
                          {...appMessages.manageYourPaymentInformation}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="controls__row">
              <div className="grid-row">
                <div className="no-grid-col card_loading">
                  <div className="controls__title">
                    <h4>
                      <FormattedMessage {...appMessages.partner} />
                    </h4>
                  </div>
                </div>
                <div className="grid-col grid-col_10 grid-col_sm-8 grid-col_xs-12">
                  <div className="grid-row">
                    <SettingBox
                      subDomain={workspace && workspace.name}
                      customClass={`card_loading controls__item_${DEFAULT_ACCENT}`}
                      img="partner"
                      name={
                        <FormattedMessage {...appMessages.partnerPortal} />
                      }
                      description={(
                        <FormattedMessage
                          {...appMessages.manageYourMarketplaceListings}
                        />
                      )}
                    />
                    <SettingBox
                      subDomain={workspace && workspace.name}
                      customClass={`card_loading controls__item_${DEFAULT_ACCENT}`}
                      img="marketplace"
                      name={
                        <FormattedMessage {...appMessages.marketplace} />
                      }
                      description={(
                        <FormattedMessage
                          {...appMessages.findAdditionBlocksAndApp}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DetailScroll>
      </>
    );
  }
}

export default Settings
