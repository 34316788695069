import Link from 'components/Link';
import ListScroll from 'components/List/ListScroll';
import VerticalButtonMenu from 'components/VerticalButtonMenu';
import TopNav from 'containers/TopNav';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { routeMapping } from 'routes';
import appMessages from 'utils/messages';
import Pagination from 'components/Pagination';
import Table from 'components/Table';
import { FlowModeler } from 'images/common';
import { FlowIcon } from '../../images/common';
import SortableRow from 'components/Table/SortableRow';

const result = [
]

const columnList = [{
  label: <FormattedMessage {...appMessages.applicationName} />,
  sortKey: 'name',
  isLocked: true,
  isSortable: true,
},
{
  label: 'Test',
  sortKey: 'name',
  isLocked: true,
  isSortable: true,
},
{
  label: 'Test',
  sortKey: 'name',
  isLocked: true,
  isSortable: true,
},
];

const getRowRender = () => {

  return {
    name: () => <div>Name</div>,
    type: () => <div>Type</div>,
  };
}

const Row = (props) => {

  return (
    <SortableRow
      {...props}
      columns={columnList}
      customRender={getRowRender()}
      appendToSetting={(
        <div
          className="actions actions_update actions_icon"
        >
          <span
            className='actions_icon_item  actions_icon_disabled'
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <span>
              <FlowModeler />
            </span>
          </span>
        </div>
      )}
    >
    </SortableRow>
  )
}

class PreLoaderList extends React.Component {
  render() {
    const { color } = this.props;

    return (
      <>
        <TopNav title={
          <FormattedMessage {...appMessages.applications} />
        }
        >
          <div className="actions-nav actions-nav-mobile">
            <VerticalButtonMenu color={color}>
            </VerticalButtonMenu>
          </div>
        </TopNav>
          <ListScroll>
            {() => (
              <div>
                <div className="headline">
                  <h1 className="headline__title loading">
                    <span>Loading</span>
                  </h1>
                </div>
                <div className={`secondary-nav secondary-nav_${color} secondary-nav_loading`}>
                  <Link
                    to={routeMapping.LIBRARY_MODULES.path}
                    className="secondary-nav__link"
                  >
                    <FormattedMessage {...appMessages.buildingBlocks} />
                  </Link>
                  <Link
                    to={routeMapping.APPLICATIONS_VISUAL_EDITOR_PAGE.path}
                    className="secondary-nav__link"
                  >
                    <FormattedMessage {...appMessages.visualEditor} />
                  </Link>

                </div>
                <Table
                  columns={[]}
                  RowComponent={Row}
                  rows={result}
                  CheckboxImage={FlowIcon}
                  isSelectable
                  withColumnSelect
                  colorClassName={color}
                  isLoading
                />
                <div className='loading'>
                  <Pagination
                    currentPage={1}
                    setCurrentPage={this.setCurrentPage}
                    onChangeLimit={this.onChangeLimit}
                    limit={10}
                    totalPage={10}
                  />
                </div>
              </div>
            )}
          </ListScroll>
      </>
    );
  }
}

export default PreLoaderList;
