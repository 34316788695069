import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';

import TdWrap from 'components/Table/TdWrap';

class SortableRow extends Component {
  state = {
    showSingleMenu: false,
  };

  renderTdContent = (sortKey) => {
    const customRenderComponent = this.props.customRender[sortKey];
    if (customRenderComponent) {
      return customRenderComponent();
    }

    return this.props[sortKey];
  };

  onToogleMenu = (e, id) => {
    e.stopPropagation();
    this.setState({ showSingleMenu: true });
    this.props.onSelectSetting(id, e);
  };

  closeMenu = () => {
    this.props.onClearSelectedSetting();
    this.setState({ showSingleMenu: false });
  };

  render() {
    const { settingContainer } = this.props;
    const tdClass = classNames('data-table__td', {
      isActive: this.state.showSingleMenu,
      'table-actions': this.props.appendToSetting,
    });

    return (
      <>
        {this.props.columns.map(column => (
          <TdWrap
            onCloseSingleMenu={this.closeMenu}
            columns={this.props.columns}
            sortKey={column.sortKey}
            className={this.props.className}
            key={column.sortKey}
          >
            {this.renderTdContent(column.sortKey)}
          </TdWrap>
        ))}
        {settingContainer && (
          <td className="data-table__td">{settingContainer}</td>
        )}
        {!settingContainer && this.props.hideSingleMenu && (
          <td className="data-table__td" />
        )}
        {!this.props.hideSingleMenu && (
          <td className={tdClass}>
            {this.props.appendToSetting}
            <span>
              <OutsideClickHandler onOutsideClick={this.closeMenu}>
                <span className={classNames('actions')}>
                  <button
                    type="button"
                    className={classNames('actions__button actions__menu', {
                      [this.props.colorClassName]: this.props.colorClassName,
                      actions__show: this.state.showSingleMenu,
                    })}
                    onClick={e => this.onToogleMenu(e, this.props.id)}
                  />
                  {React.Children.map(this.props.children, child => React.cloneElement(child, {
                    closeMenu: this.closeMenu,
                    onToogleMenu: this.onToogleMenu,
                    showMenu: this.state.showSingleMenu,
                  }))}
                </span>
              </OutsideClickHandler>
            </span>
          </td>
        )}
      </>
    );
  }
}

SortableRow.propTypes = {
  colorClassName: PropTypes.string,
  columns: PropTypes.array,
  children: PropTypes.node,
  customRender: PropTypes.object,
  hideSingleMenu: PropTypes.bool,
};

export default SortableRow;
