import axios from 'axios';
import history from 'utils/history';
import { defaultNotifier } from 'functions/notificationHandler';
import localForage from 'localforage';
import { routeMapping as routes } from './constants';
import { jsonToGraphQLQuery } from 'json-to-graphql-query'
import axiosObj from './config'
import { AUTH_BASE } from 'api/constants';
import * as jose from 'jose'


const WORKSPACE_LOGIN = {
  token: true,
  refreshToken: true,
}

/**
 * Wrapper for axios.
 * @param {string} action   - Action for axios
 * @param {string} resource - Endpoint to use
 * @param {(* | null)} [payload = null] - Data to post
 * @param {(* | null)} [config = null] - Config Axios
 */

function createAxiosInstance(unauthorizedCallback) {
  return axios.create({
    headers: {
      'content-type': 'application/json',
    },
    validateStatus: (status) => {
      const unauthorizedStatus = 401;
      if (status === unauthorizedStatus) {
        unauthorizedCallback();
      }
      return status >= 200 && status < 400;
    },
    onUploadProgress: null,
    cancelToken: null,
  });
}

async function logout() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('userData');
  localStorage.clear();
  await localForage.setItem('noderedModules', null);
  await localForage.setItem('hideMaintenanceBanner', false);
  history.push(routes.LOGIN.path);
  window.location.reload();
}

const checkForRefreshToken = () => {
  const requestToken = localStorage.getItem('refreshToken')
  requestAccessToken({token: requestToken})
}

function isTokenExpired(error) {
  if (error && error.data && error.data.errors) {
    const { message } = error.data.errors[0];

    if (message.includes('jwt expired')
     || message.includes('invalid token')
      || message.includes('invalid signature')
      || message.includes('You are denied system access')
       || message.includes('jwt malformed')) {
          return checkForRefreshToken();
    }
    return error;
  }
}

export const axiosInstance = createAxiosInstance(logout);


function isAccessTokenExpired(state) {
  if (state && state.exp) {
    return 1000 * state.exp - (new Date()).getTime() < 5000
  }
  return true
}

function isRefreshTokenExpired(state) {
  if (state && state.exp) {
    return 1000 * state.exp - (new Date()).getTime() < 5000
  }
  return true
}

export default function request(params) {
  const token = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');

  const accessTokenInfo = token && jose.decodeJwt(token)
  const refreshTokenInfo = refreshToken && jose.decodeJwt(refreshToken)

  if(isAccessTokenExpired(accessTokenInfo)) {
    if(!isRefreshTokenExpired) {
      return checkForRefreshToken()
    }

    if(refreshToken && isRefreshTokenExpired(refreshTokenInfo)){
      logout();
      return defaultNotifier(
        'You are not allowed to access this resource',
      );
    }
    
  }


  return axiosInstance({
    headers: {
      'content-type': 'application/json',
      authorization: token ? `Bearer ${token}` : undefined,
    },
    ...params,
  })
    .then((res) => {
      if (res.data
        && res.data.errors
        && res.data.errors[0]
        && res.data.errors[0].message === 'You are not allowed to access this resource') {

        window.location.href = '/403-page';
        defaultNotifier(
          'You are not allowed to access this resource',
        );
      }
      return res;
    })
    .catch(err => (err.response
      ? isTokenExpired(err.response)
      : {
        errors: [
          {
            message: 'Something went wrong.',
          },
        ],
      }));
}


function requestAccessToken(params) {
  const query = {
    mutation: {
      refreshToken: {
        __args: {
          ...params
        },
        ...WORKSPACE_LOGIN,
      },
    },
  };

  const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

  axios({
    method: 'POST',
    url: AUTH_BASE,
    data: {
      variables: {},
      query: gqlQuery,
    },
  })
    .then((res) => {
      if(res.data.errors) {
        defaultNotifier(
          'Your session has expired, please login again to continue.',
        );
        return logout();
      }
      
      if(res.data.data) {
        const result = res.data.data.refreshToken;
        
        localStorage.setItem('accessToken', result.token)
        localStorage.setItem('refreshToken', result.refreshToken)
  
        window.location.reload()
        return res;
      }

    })
    .catch(error => {
      if (error && error.data && error.data.errors) {
        const { message } = error.data.errors[0];
        if (message.includes('jwt expired')
         || message.includes('invalid token')
          || message.includes('jwt must be provided')
          || message.includes('invalid signature')
          || message.includes('You are denied system access')
           || message.includes('jwt malformed')) {
              defaultNotifier(
                'Your session has expired, please login again to continue.',
              );
              logout();
        }
        return error;
      }
    })}
