export const GET_DEVICE_REQUEST = 'DEVICE/GET_DEVICE_REQUEST';
export const GET_DEVICE_SUCCESS = 'DEVICE/GET_DEVICE_SUCCESS';

export const GET_DEVICE_DETAILS_REQUEST = 'DEVICE/GET_DEVICE_DETAILS_REQUEST';
export const GET_DEVICE_DETAILS_ERROR = 'DEVICE/GET_DEVICE_DETAILS_ERROR';
export const GET_DEVICE_DETAILS_SUCCESS = 'DEVICE/GET_DEVICE_DETAILS_SUCCESS';

export const GET_DEVICE_NODES_REQUEST = 'DEVICE/GET_DEVICE_NODES_REQUEST';
export const GET_DEVICE_NODES_ERROR = 'DEVICE/GET_DEVICE_NODES_ERROR';
export const GET_DEVICE_NODES_SUCCESS = 'DEVICE/GET_DEVICE_NODES_SUCCESS';
export const CLEAR_DEVICE_NODES = 'DEVICE/CLEAR_DEVICE_NODES';
export const NODE_VERSION_REQUEST = 'NODE_VERSION_REQUEST';
export const NODE_VERSION_SUCCESS = 'NODE_VERSION_SUCCESS';

export const GET_CONTAINER_REQUEST = 'GET_CONTAINER_REQUEST';
export const GET_CONTAINER_SUCCESS = 'GET_CONTAINER_SUCCESS';
export const GET_CONTAINER_ERROR = 'GET_CONTAINER_ERROR';

export const GET_VIDEO_STREAM_REQUEST = 'DEVICE/GET_VIDEO_STREAM_REQUEST';
export const GET_VIDEO_STREAM_ERROR = 'DEVICE/GET_VIDEO_STREAM_ERROR';
export const GET_VIDEO_STREAM_SUCCESS = 'DEVICE/GET_VIDEO_STREAM_SUCCESS';

export const GET_SUPPORTED_DEVICES_REQUEST = 'DEVICE/GET_SUPPORTED_DEVICES_REQUEST';
export const GET_SUPPORTED_DEVICES_SUCCESS = 'DEVICE/GET_SUPPORTED_DEVICES_SUCCESS';

export const GET_DEVICE_ERROR = 'DEVICE/GET_DEVICE_ERROR';
export const UPDATE_DEVICE_REQUEST = 'DEVICE/UPDATE_DEVICE_REQUEST';

export const EDIT_INFORMATION_POPUP = 'EDIT_INFORMATION_POPUP';
export const EDIT_GROUPS_POPUP = 'EDIT_GROUPS_POPUP';

export const CARD_VIEW = 'card';
export const TABLE_VIEW = 'list';
