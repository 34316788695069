import {
  takeLatest,
  call,
  put,
  select,
} from 'redux-saga/effects';
import {
  getAllDevices,
  getPendingDevices
} from 'api/devices';
import {
  assignProfile,
  getProfiles,
  getCurrentDeploymentCount,
} from 'api/release';
import {
  updateStartedTrackerRequest,
} from 'containers/Settings/Workspace/actions';
import {
  defaultNotifier,
} from 'functions/notificationHandler';
import { orderBy, sortBy } from 'lodash';
import { intl } from 'containers/LanguageProvider/intlProvider';
import messages from 'utils/messages';
import {
  getProfilesError,
  getProfilesSuccess,
  getDevicesError,
  getDevicesSuccess,
  assignDevicesSuccess,
  getPendingDeviceSuccess
} from './actions';
import {
  GET_PROFILES_REQUEST,
  GET_DEVICES_REQUEST,
  ASSIGN_DEVICES_REQUEST,
  GET_PENDING_DEVICE_REQUEST
} from './constants';

export const workspaceState = state => state.workspace;

function* getProfilesSaga(action) {
  try {
    const response = yield call(getProfiles, action.payload);

    if (response.errors) {
      defaultNotifier(response.data.errors);
      yield put(getProfilesError(response.data.errors));
    } else {
      const list = sortBy(response.data.data.profiles, ['createdAt']).reverse();

      getCurrentDeploymentCount;
      yield put(getProfilesSuccess(list));
    }
  } catch (err) {
    defaultNotifier(err);
    yield put(getProfilesError(err));
  }
}

function* getDevicesSaga(action) {
  try {
    const response = yield call(getAllDevices, action.payload);

    if (response.errors) {
      defaultNotifier(response.errors);
      yield put(getDevicesError(response.data.errors));
    } else {
      yield put(getDevicesSuccess({ ...action.payload, ...response.data.data.devices }));
    }
  } catch (err) {
    defaultNotifier(err);
    yield put(getDevicesError(err));
  }
}

function* getPendingDevicesSaga(action) {
  try {
    const response = yield call(getPendingDevices, action.payload);

    if (response.errors) {
      defaultNotifier(response.errors);
      yield put(getDevicesError(response.data.errors));
    } else {
      const sortData = orderBy(
        response.data.data.pendingDevices,
        [
          item => item.approvalStatus === 'pending' ? 0 : 1,
          'approvalStatus',
          'approvedAt'
        ],
        ['asc', 'asc', 'desc']
      )

      yield put(getPendingDeviceSuccess(sortData))
    }
  } catch (err) {
    defaultNotifier(err);
    yield put(getDevicesError(err));
  }
}

function* assignDevices(action) {
  try {
    const response = yield call(assignProfile, action.payload);

    if (response.data.errors) {
      defaultNotifier(response.data.errors);
    } else {
      yield put(assignDevicesSuccess());

      if (response.data.data.assignProfileToDevices.skippedCount) {
        if (action.meta) {
          action.meta.onCallback(response.data);
        }

        return defaultNotifier(`${intl.formatMessage({ ...messages.successfullyCreated })}.${' '}
        ${response.data.data.assignProfileToDevices.skippedCount}${' '}
        ${intl.formatMessage({ ...messages.devicesWereSkipped })}`);
      }

      if (action.meta) {
        action.meta.onCallback(response.data);
      }

      const project = yield select(workspaceState);

      if (project
        && project.toJS()
        && project.toJS().tracker
        && project.toJS().tracker.deployFirstProfile === 'open') {
        yield put(updateStartedTrackerRequest({
          task: 'deployFirstProfile',
        }));
      }
      return defaultNotifier(intl.formatMessage({ ...messages.successfullyCreated }));
    }
  } catch (err) {
    defaultNotifier(err);
  }
}

export default function* libraryRootSaga() {
  yield takeLatest(GET_PROFILES_REQUEST, getProfilesSaga);
  yield takeLatest(GET_DEVICES_REQUEST, getDevicesSaga);
  yield takeLatest(GET_PENDING_DEVICE_REQUEST, getPendingDevicesSaga);
  yield takeLatest(ASSIGN_DEVICES_REQUEST, assignDevices);
}
