import {
  GET_DEVICE_REQUEST,
  GET_DEVICE_SUCCESS,
  GET_DEVICE_ERROR,
  GET_DEVICE_DETAILS_REQUEST,
  GET_DEVICE_DETAILS_SUCCESS,
  GET_DEVICE_DETAILS_ERROR,
  GET_SUPPORTED_DEVICES_REQUEST,
  GET_SUPPORTED_DEVICES_SUCCESS,
  UPDATE_DEVICE_REQUEST,
  GET_DEVICE_NODES_REQUEST,
  GET_DEVICE_NODES_SUCCESS,
  GET_DEVICE_NODES_ERROR,
  GET_VIDEO_STREAM_REQUEST,
  GET_VIDEO_STREAM_SUCCESS,
  GET_VIDEO_STREAM_ERROR,
  GET_CONTAINER_REQUEST,
  GET_CONTAINER_SUCCESS,
  GET_CONTAINER_ERROR,
  CLEAR_DEVICE_NODES,
  NODE_VERSION_REQUEST,
  NODE_VERSION_SUCCESS,
} from './constants';

export function getDeviceRequest(payload) {
  return {
    type: GET_DEVICE_REQUEST,
    payload,
  };
}

export function updateDeviceRequest(payload, onCallback) {
  return {
    type: UPDATE_DEVICE_REQUEST,
    payload,
    meta: { onCallback },
  };
}

export function getDeviceSuccess(payload) {
  return {
    type: GET_DEVICE_SUCCESS,
    payload,
  };
}

export function getSupportedListRequest(payload) {
  return {
    type: GET_SUPPORTED_DEVICES_REQUEST,
    payload,
  };
}

export function getSupportedListSuccess(payload) {
  return {
    type: GET_SUPPORTED_DEVICES_SUCCESS,
    payload,
  };
}

export function getDeviceError(errors) {
  return {
    type: GET_DEVICE_ERROR,
    errors,
  };
}

export function getDeviceDetailsRequest(payload) {
  return {
    type: GET_DEVICE_DETAILS_REQUEST,
    payload,
  };
}

export function getDeviceDetailsSuccess(payload) {
  return {
    type: GET_DEVICE_DETAILS_SUCCESS,
    payload,
  };
}

export function getDeviceDetailsError(errors) {
  return {
    type: GET_DEVICE_DETAILS_ERROR,
    errors,
  };
}

export function getDeviceNodesRequest(payload) {
  return {
    type: GET_DEVICE_NODES_REQUEST,
    payload,
  };
}

export function getNodeVersionsRequest(payload) {
  return {
    type: NODE_VERSION_REQUEST,
    payload,
  };
}

export function getNodeVersionsSuccess(payload) {
  return {
    type: NODE_VERSION_SUCCESS,
    payload,
  };
}


export function clearDeviceNodesRequest(payload) {
  return {
    type: CLEAR_DEVICE_NODES,
    payload,
  };
}

export function getDeviceNodesSuccess(payload) {
  return {
    type: GET_DEVICE_NODES_SUCCESS,
    payload,
  };
}

export function getDeviceNodesError(errors) {
  return {
    type: GET_DEVICE_NODES_ERROR,
    errors,
  };
}

export function getDeviceContainersRequest(payload) {
  return {
    type: GET_CONTAINER_REQUEST,
    payload,
  };
}

export function getDeviceContainersSuccess(payload) {
  return {
    type: GET_CONTAINER_SUCCESS,
    payload,
  };
}

export function getDeviceContainersError(errors) {
  return {
    type: GET_CONTAINER_ERROR,
    errors,
  };
}

export function getVideoStreamsRequest(payload) {
  return {
    type: GET_VIDEO_STREAM_REQUEST,
    payload,
  };
}

export function getVideoStreamsSuccess(payload) {
  return {
    type: GET_VIDEO_STREAM_SUCCESS,
    payload,
  };
}

export function getVideoStreamsError(errors) {
  return {
    type: GET_VIDEO_STREAM_ERROR,
    errors,
  };
}
