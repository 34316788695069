import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, sortBy, uniqBy, uniq, isEqual } from 'lodash'

import AddFilter from './AddFilter'
import FilterItem from './FilterItem'

class Filter extends React.Component {
  state = {
    visibleFilter: '',
    selectedFilters: null,
    filterColumnNames: this.props.columns
  }

  onReset = () => {
    this.setState(
      {
        visibleFilter: '',
        selectedFilters: null,
        filterColumnNames: this.props.columns
      },
      () => {
        this.addDefaultValue()
      }
    )
  }

  // eslint-disable-next-line
  componentWillReceiveProps(nextProps) {
    const { resetFilter, defaultFilter, data } = this.props

    if (
      (defaultFilter && defaultFilter[0] && defaultFilter[0].columns?.length !== nextProps.defaultFilter[0].columns?.length) ||
      (defaultFilter && defaultFilter[0] && defaultFilter[0].value !== nextProps.defaultFilter[0]?.value) ||
      (data && (data?.length !== nextProps.data?.length))
    ) {
      this.addNextDefaultValue(nextProps.defaultFilter)
    }

    if (nextProps.resetFilter !== resetFilter && nextProps.resetFilter) {
      this.onReset()
    }
  }

  addDefaultValue = () => {
    if (!this.props.defaultFilter) {
      return
    }
    const { defaultFilter } = this.props
    let filters = []

    defaultFilter.map((filterItem) => {
      const {
        value = null,
        readyOnly,
        field,
        columns,
        ids,
        isDefault,
        hideAll
      } = filterItem

      const selectedFilters = this.props.columns.find(
        item => item.field === field
      ) || {}

      selectedFilters.filterValue = value
      const allColumns = columns
        && columns.map(group => ({
          label: group,
          name: group,
          customValue: selectedFilters.customValue,
          customField: selectedFilters.customField,
          type: selectedFilters.type
        }))

      selectedFilters.columns = allColumns
      selectedFilters.isDefault = isDefault
      selectedFilters.ids = ids
      selectedFilters.readyOnly = readyOnly
      selectedFilters.hideAll = hideAll

      const filterColumnNames = this.props.columns.filter(
        item => item.field !== field
      )

      filters = {
        ...filters,
        [field]: selectedFilters
      }

      return this.setState({
        filterColumnNames: [...this.state.filterColumnNames, filterColumnNames]
      })
    })
    this.setFilterValue(filters)
  }

  addNextDefaultValue = (defaultFilter) => {
    if (!defaultFilter) {
      return
    }
    let filters = []

    defaultFilter.map((filterItem) => {

      const {
        value = null,
        readyOnly,
        field,
        columns,
        ids,
        isDefault,
        showSelectedCount,
        hideAll
      } = filterItem

      const selectedFilters = this.props.columns.find(
        item => item.field === field
      ) || {}

      selectedFilters.filterValue = value

      const allColumns = columns
        && columns.map(group => ({
          label: group,
          name: group,
          customValue: selectedFilters.customValue,
          customField: selectedFilters.customField,
          type: selectedFilters.type
        }))

      selectedFilters.columns = allColumns
      selectedFilters.isDefault = isDefault
      selectedFilters.ids = ids
      selectedFilters.readyOnly = readyOnly
      selectedFilters.showSelectedCount = showSelectedCount
      selectedFilters.hideAll = hideAll

      const filterColumnNames = this.props.columns.filter(
        item => item.field !== field
      )

      filters = {
        ...filters,
        [field]: selectedFilters
      }

      return this.setState({
        filterColumnNames: [...this.state.filterColumnNames, filterColumnNames]
      })
    })
    this.props.setFilterOnAddNext && this.setFilterValue(filters)
  }

  componentDidMount() {
    if (this.props.defaultFilter) {
      this.addDefaultValue()
    }
  }

  setFilterValue = (selectedFilters, source) => {
    this.setState({ selectedFilters })

    this.props.onChange(selectedFilters, source)
  }

  onAddFilterType = (selectedHeader) => {
    const { data, disabled } = this.props
    const { filterColumnNames } = this.state

    if (disabled) {
      return false
    }
    let allColumns = []
    let columns = []

    if (selectedHeader.custom) {
      data.forEach(item => item.modules.forEach(module => allColumns.push({
        label: module.name,
        name: module.id,
        type: selectedHeader.type
      })))

      allColumns = uniqBy(allColumns, 'label')
    }

    columns = uniq(
      data
        .filter(group => group[selectedHeader.field])
        .map(group => group[selectedHeader.field]),
    ).map((group) => {
      if (typeof group === 'number') {
        return { label: group, type: selectedHeader.type }
      }

      if (typeof group !== 'object') {
        return { label: group, name: group, type: selectedHeader.type }
      }

      if (selectedHeader.type === 'tags') {
        return group
      }

      if (group && group[0]) {
        return { label: group || [], name: group || [], type: selectedHeader.type }
      }

      return { label: group.name, name: group.id, type: selectedHeader.type }
    })

    if (selectedHeader.columns) {
      columns = selectedHeader.columns
    }
    if (selectedHeader.customField) {
      columns = uniq(
        data
          .filter(group => group[selectedHeader.customField])
          .map(group => group[selectedHeader.customField]),
      ).map((group) => {
        if (typeof group !== 'object') {
          return { label: group, name: group, type: selectedHeader.type }
        }

        if (selectedHeader.type === 'tags') {
          return group
        }
        return { label: group.name, name: group.id, type: selectedHeader.type }
      })

      if (selectedHeader.customField.includes('.') && selectedHeader.customField.split('.') && selectedHeader.customField.split('.').length) {
        columns = uniq(
          data
            .filter(group => group[selectedHeader.customField.split('.')[0]][selectedHeader.customField.split('.')[1]])
            .map(group => group[selectedHeader.customField.split('.')[0]][selectedHeader.customField.split('.')[1]]),
        ).map((group) => {
          if (typeof group !== 'object') {
            return { label: group, name: group, type: selectedHeader.type }
          }

          if (selectedHeader.type === 'tags') {
            return group
          }
          return { label: group.name, name: group.id, type: selectedHeader.type }
        })
      }

    }

    if (selectedHeader.customValue) {
      columns = data
        && data.map(group => {
          return ({
            label: (selectedHeader.customKey[group[selectedHeader.field]]),
            name: selectedHeader.customKey[group[selectedHeader.field]],
            customValue: selectedHeader.customValue,
            customField: selectedHeader.customField,
            type: selectedHeader.type
          })
        })

      columns = uniqBy(columns, 'label')
    }

    const allFilters = filterColumnNames
      .filter(header => header.field !== selectedHeader.field)
      .map(header => ({ ...header, isActive: false }))

    this.setState({
      filterColumnNames: allFilters,
      visibleFilter: selectedHeader.field
    })

    const selectedFilters = {
      ...this.state.selectedFilters,
      [selectedHeader.field]: {
        ...selectedHeader,
        columns: selectedHeader.custom ? allColumns : columns
      }
    }

    this.setState({ selectedFilters })
  }

  onRemoveFilterType = (name, isUpdated, e) => {
    if (e) {
      e.stopPropagation()
    }
    const { filterColumnNames, selectedFilters } = this.state

    const currentFilter = Object.assign({}, selectedFilters)
    const unFiltered = sortBy(
      [...filterColumnNames, selectedFilters[name]],
      ['id']
    )
    delete currentFilter[name].filterValue
    delete currentFilter[name].ids
    delete currentFilter[name]

    if (isUpdated) {
      return this.setState(
        {
          filterColumnNames: unFiltered,
          selectedFilters: Object.keys(currentFilter).length
            ? currentFilter
            : null
        },
        () => this.props.onChange(this.state.selectedFilters)
      )
    }
    this.setState({
      filterColumnNames: unFiltered,
      selectedFilters: Object.keys(currentFilter).length ? currentFilter : null
    })
  }

  render() {
    const { customClass, disabled, children, showSelectedCount } = this.props
    const { filterColumnNames, visibleFilter, selectedFilters } = this.state

    return (
      <>
        {!isEmpty(selectedFilters)
          && Object.keys(selectedFilters).map((selectedFilter, index) => (
            <FilterItem
              setFilterValue={this.setFilterValue}
              key={index}
              hideAll={selectedFilters[selectedFilter]?.hideAll}
              disabled={disabled}
              isDefault={selectedFilters[selectedFilter]?.isDefault}
              customClass={customClass}
              visibleFilter={visibleFilter}
              onRemove={this.onRemoveFilterType}
              data={selectedFilters[selectedFilter]}
              selectedFilters={selectedFilters}
              showSelectedCount={showSelectedCount ? showSelectedCount : selectedFilters[selectedFilter]?.showSelectedCount}
            />
          ))}
        {this.props.visibleAddButton && (
          <AddFilter
            disabled={disabled}
            customClass={customClass}
            onRemove={this.onRemoveFilterType}
            filters={filterColumnNames}
            onAdd={this.onAddFilterType}
            defaultFilter={this.props.defaultFilter}
          />
        )}
        {children}
      </>
    )
  }
}

Filter.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  onChange: PropTypes.func,
  resetFilter: PropTypes.bool,
  disabled: PropTypes.bool,
  customClass: PropTypes.string,
  defaultFilter: PropTypes.array,
  visibleAddButton: PropTypes.bool,
  showSelectedCount: PropTypes.bool
}

Filter.defaultProps = {
  visibleAddButton: true
}

export default Filter